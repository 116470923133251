<template>
  <div>
    <b-card
      no-body
      class="card-dashboard-section"
    >
      <b-card-header class="dashboard-header">
        <b-card-title>
          <div class="dashbord-nav help-header">
            <p class="task-timesheet m-0">
              Upload Media
            </p>
          </div>
        </b-card-title>
        <div class="d-flex align-items-center">
          <option-drop-down
            v-model="perPage"
            class="mr-2"
          />
          <button
            v-show="userInfo.role == 'SA'"
            v-b-modal.UploadManual
            class="commonBtn"
          >
            Upload
          </button>
        </div>
      </b-card-header>
    </b-card>
    <div>
      <validation-observer ref="clientObserver">
        <div class="addtask task-detail-modal">
          <b-modal
            id="UploadManual"
            cancel-variant="outline-secondary"
            class="mymodal p-2"
            centered
            size="lg"
            @hidden="sidebarToggle"
          >
            <template #modal-title>
              <h3 class="sprint-from-title sprint-bottom ml-0 mb-1 pb-50">
                Upload Media
              </h3>

            </template>
            <div>
              <b-row class="addtask-content">
                <b-col cols="12">
                  <b-form-group
                    label="Media Name"
                    label-for="mediaName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="mediaName"
                        v-model="media_name"
                        placeholder="Media Name"
                        name="Media Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <vue-dropzone
                    id="dropzone"
                    ref="myVueDropzone"
                    :options="dropzoneOptions"
                    @vdropzone-success="handleFileAdding"
                    @vdropzone-removed-file="removeFile"
                    @vdropzone-error="handleFileError"
                  />
                  <small class="text-danger">{{ FileErrorMsg }}</small>
                </b-col>
              </b-row>
            </div>
            <template #modal-footer>
              <b-button
                class="Upload-btn"
                :disabled="loader"
                @click="validationForm()"
              >
                Upload
              </b-button>
              <b-button
                class="cancel-btn"
                @click="$bvModal.hide('UploadManual')"
              >Cancel</b-button>
            </template>
          </b-modal>
        </div>
      </validation-observer>
    </div>
    <div class="table role test-plan-table task-section-scrollbar">
      <table-loader
        v-if="loader"
        :fields="fields"
      />
      <b-row v-else>
        <b-col cols="12">
          <!-- data table start-->

          <b-table
            responsive
            :items="mediaList"
            :fields="fields"
            show-empty
            class="scrollable-element user-table-items tableDesigns mastertblColor table-responsive non-project-table"
          >
            <template #cell(media_name)="data">
              <p
                v-b-tooltip.hover.top="data.item.media_name"
                class="tableData data-hover"
              >
                {{ data.item.media_name }}
              </p>
            </template>
            <template #cell(url)="data">
              <p class="tableData" />
              <p
                class="cursor-pointer on-hobver-effect"
                @click="openFileInNewTab(data.item.url)"
              >
                {{ data.item.url ? url + "/" + data.item.id : null }}
              </p>
            </template>
            <template #cell(copyLink)="data">
              <div
                v-if="data.item.url"
                class="copy-link ml-2"
                @click="copyToClipboard(url + '/' + data.item.id)"
              >
                <feather-icon
                  icon="CopyIcon"
                  size="15"
                  color="#2178fb"
                  class="tableData"
                />
              </div>
            </template>

            <!-- Action -->
            <template
              v-if="userInfo.role == 'SA'"
              #cell(action)="data"
            >
              <span class="ml-1">
                <feather-icon
                  v-b-tooltip.hover.top="'Delete'"
                  icon="Trash2Icon"
                  role="button"
                  class="text-danger"
                  size="18"
                  @click="deleteConfirmation(data.item.id)"
                />
              </span>
            </template>
          </b-table>
          <!-- data table complete -->
          <img
            v-if="mediaList.length == 0"
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >
        </b-col>

      </b-row>
      <!-- pagination section -->
      <Pagination
        v-if="totalCount>0 && !loader"
        :current-page="currentPage"
        :per-page="perPage"
        :total-count="totalCount"
        @currentPage="currentPage = $event"
      />
      <!-- pagination section complete here -->
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    vueDropzone: vue2Dropzone,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      fields: [
        { key: 'media_name', label: 'Media Name' },
        {
          key: 'url',
          label: 'Media Link',
        },
        {
          key: 'copyLink',
          label: 'Copy Link',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      file: [],
      task_attachments: [],
      remove_attachments: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/project/task/image-store`,
        maxFiles: 1,
        uploadMultiple: false,
        parallelUploads: 100,
        addRemoveLinks: true,
        addViewLinks: true,
        acceptedFiles:
          '.jpeg,.jpg, .png, .txt, .pdf,.ppt,pptx, .doc, .csv, .docx, .xls, .xlsx, .mov, .mp4',
        dictDefaultMessage:
          "<b class='drop-title'>Drag & drop files</b> <br> <p class='drop-req'>Supported formats: JPEG, PNG, JPG, <br /> TXT, PDF, PPT, DOC, CSV, DOCX, XLS, XLSX <br />",
      },
      totalCount: 0,
      loader: false,
      media_name: null,
      mediaList: [],
      url: null,
      currentPage: 1,
      perPage: 10,
      FileErrorMsg: null,
      errorFileList: [],
    }
  },
  watch: {
    currentPage() {
      this.getMediaList()
    },
    perPage() {
      if (this.currentPage == 1) {
        this.getMediaList()
      } else {
        this.currentPage = 1
      }
    },
  },
  mounted() {
    this.url = window.location.href
    this.getMediaList()
  },
  methods: {
    /**
     * check the form validation
     * @returns if success true then call uploadMediaFile Method
     */
    validationForm() {
      if (this.$refs.myVueDropzone.getAcceptedFiles().length == 0) {
        this.FileErrorMsg = 'Attachment is required'
      } else if (!this.FileErrorMsg) {
        this.$refs.clientObserver.validate().then(success => {
          if (success) {
            this.uploadMediaFile()
          }
        })
      }
    },
    handleFileAdding(file) {
      if (file.status == 'success') {
        this.FileErrorMsg = null
        this.file.push(file)
      }
    },

    // use for validation only
    handleFileError(file, message) {
      this.FileErrorMsg = message
      this.errorFileList.push(file)
    },
    removeFile(file) {
      if (file.id) {
        this.remove_attachments.push(file.id)
        const task_attachmentsIndex = this.task_attachments.findIndex(
          x => x.id === file.id,
        )
        this.task_attachments.splice(task_attachmentsIndex, 1)
      } else {
        const fileIndex = this.file.findIndex(
          x => x.upload.uuid === file.upload.uuid,
        )

        this.file.splice(fileIndex, 1)

        // use for validation only
        const ErrorFileIndex = this.errorFileList.findIndex(
          x => x.upload.uuid === file.upload.uuid,
        )
        this.errorFileList.splice(ErrorFileIndex, 1)

        this.FileErrorMsg = this.errorFileList && this.errorFileList.length
          ? this.FileErrorMsg
          : null
      }
    },

    async uploadMediaFile() {
      this.loader = true

      const formData = {
        media_name: this.media_name,
        files: this.file ? await Promise.all(this.file.map(async file => ({
          name: file.name,
          file: await this.convertFileToBase64(file),
        }))) : [],
      }

      const response = await this.getHTTPPostResponse(
        'admin/master/user-media/create/',
        formData,
        true,
      )
      if (response && response.status) {
        this.$root.$emit('bv::toggle::modal', 'UploadManual')
        this.media_name = null
        this.currentPage = 1
        this.getMediaList()
      }
      this.loader = false
    },

    async getMediaList($state) {
      this.loader = true
      const input = {
        per_page: this.perPage,
        page: this.currentPage,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user-media',
        input,
      )

      if (response && response.status == 200) {
        const { data } = response
        this.totalCount = data.count
        this.mediaList = data.userMedia
      }
      this.loader = false
    },

    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$bvToast.toast('Link copied to clipboard', {
          toaster: 'b-toaster-top-right',
          solid: true,
          title: 'Success',
          variant: 'success',
        })
      } catch (error) {
        this.$bvToast.toast('Unable to copy link to clipboard', {
          toaster: 'b-toaster-top-right',
          solid: true,
          title: 'Error',
          variant: 'danger',
        })
      }
    },

    /**
     * confirmation to delete media
     */
    async deleteConfirmation(id) {
      const title = 'Are you sure you want to delete this Media?'
      const confirmation = await this.conformationAlert(true, title, 'Yes, delete it!', '<p>You won’t be able to revert this!</p>')
      if (confirmation.isConfirmed) {
        this.deleteMedia(id)
      }
    },

    async deleteMedia(id) {
      const response = await this.getHTTPDeleteResponse(
        `admin/master/user-media/delete/${id}`,
        {},
        true,
      )
      if (response && response.status == 200) {
        this.getMediaList()
      }
    },

    sidebarToggle() {
      this.media_name = null
      this.FileErrorMsg = null
      this.$refs.clientObserver.reset()
    },
  },
}
</script>

<style lang="scss">
@import "../../../assets/scss/component-css/help.scss";
</style>
